import React, { useEffect } from 'react'

// Components
import Layout from '../components/layout'
import ProfileSlider from '../components/profile-slider'
import SEO from '../components/seo'

const Profile = () => {
  const hoverHeaderText = () => {
    const headerDivs = document.querySelectorAll('.text-container')
    headerDivs.forEach((e) => {
      e.addEventListener('mouseover', function () {
        headerDivs.forEach((e) => {
          e.classList.remove('solid')
        })
        e.classList.add('solid')
      })
    })
  }

  useEffect(() => {
    hoverHeaderText()
  }, [])

  return (
    <>
      <Layout page="profile">
        <SEO title="Profile" />
        <section className="profile--intro">
          <div className="left-column">
            <p>
              <strong>About me</strong>
            </p>
            <p>
              I am based in Brisbane, and develop websites for both national and international
              clients. Although I largely focus on web development, I have also worked in various IT
              roles, and have a keen interest in cyber security. I also conduct penetration tests
              and phishing campaigns for SMEs and SMBs.
            </p>
          </div>
          <div className="right-column">
            <div className="header-text">
              <div className="text-container">
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
              </div>
              <div className="text-container">
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
              </div>
              <div className="text-container">
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
              </div>
              <div className="text-container">
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
              </div>
              <div className="text-container solid">
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
              </div>
              <div className="text-container">
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
              </div>
              <div className="text-container">
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
              </div>
              <div className="text-container">
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
              </div>
              <div className="text-container">
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
              </div>
              <div className="text-container">
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
              </div>
              <div className="text-container">
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
              </div>
              <div className="text-container">
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
              </div>
              <div className="text-container">
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
                <span>Experience</span>
              </div>
            </div>
          </div>
        </section>
        <section className="profile--work">
          <div className="left-column">
            <p>
              <strong>Worked for</strong>
            </p>
          </div>
          <div className="right-column">
            <div className="work">
              <span>Myself</span>
              <span className="vertical-divider">|</span>
              <span>Freelance Web Developer</span>
              <span className="vertical-divider">|</span>
              <span className="year">2017 - now</span>
              <div className="backdrop del">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <div className="work">
              <span>Hedgey Finance</span>
              <span className="vertical-divider">|</span>
              <span>Senior Full-stack Developer</span>
              <span className="vertical-divider">|</span>
              <span className="year">2023 - now</span>
              <div className="backdrop del">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <div className="work">
              <span>Mycelium</span>
              <span className="vertical-divider">|</span>
              <span>Full-stack Web Developer</span>
              <span className="vertical-divider">|</span>
              <span className="year">2022</span>
              <div className="backdrop del">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <div className="work">
              <span>Deloitte</span>
              <span className="vertical-divider">|</span>
              <span>Cyber Security Analyst</span>
              <span className="vertical-divider">|</span>
              <span className="year">2021</span>
              <div className="backdrop del">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <div className="work">
              <span>Queensland University of Technology</span>
              <span className="vertical-divider">|</span>
              <span>Web Designer</span>
              <span className="vertical-divider">|</span>
              <span className="year">2016 - 2021</span>
              <div className="backdrop qut">
                <span></span>
                <span></span>
              </div>
            </div>
            <div className="work">
              <span>Urban Circus</span>
              <span className="vertical-divider">|</span>
              <span>Sysadmin</span>
              <span className="vertical-divider">|</span>
              <span className="year">2016 - 2017</span>
              <div className="backdrop uc">
                <span></span>
                <span></span>
              </div>
            </div>
            <div className="work">
              <span>Microsoft</span>
              <span className="vertical-divider">|</span>
              <span>Brand Ambassador</span>
              <span className="vertical-divider">|</span>
              <span className="year">2014 - 2017</span>
              <div className="backdrop ms">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </section>
        <section className="languages">
          <h1>Skills</h1>
          <div className="languages--overlay pattern-diagonal-lines-sm"></div>
          <ProfileSlider />
        </section>
        <section className="profile--education">
          <div className="left-column">
            <p>
              <strong>Educated at</strong>
            </p>
          </div>
          <div className="right-column">
            <div className="education">
              <span>Stack Overflow</span>
              <span className="vertical-divider">|</span>
              <span className="year">Ongoing</span>
            </div>
            <div className="education">
              <span>QUT</span>
              <span className="vertical-divider">|</span>
              <span>Master of Information Technology&nbsp;</span>
              <span>(Cyber Security and Network Engineering)</span>
              <span className="vertical-divider">|</span>
              <span className="year">2020</span>
            </div>
            <div className="education">
              <span>QUT</span>
              <span className="vertical-divider">|</span>
              <span>Bachelor of Information Technology&nbsp;</span>
              <span>(Computer Science and Interactive Visual Design)</span>
              <span className="vertical-divider">|</span>
              <span className="year">2018</span>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default Profile
