import React from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'

// Assets
import bootstrap from '/static/img/profile/languages/bootstrap.png'
import c from '/static/img/profile/languages/c.png'
import c_sharp from '/static/img/profile/languages/c_sharp.png'
import c_plusplus from '/static/img/profile/languages/c++.png'
import css3 from '/static/img/profile/languages/css3.png'
import html5 from '/static/img/profile/languages/html5.png'
import java from '/static/img/profile/languages/java.png'
import jquery from '/static/img/profile/languages/jquery.png'
import js from '/static/img/profile/languages/js.png'
import MySQL from '/static/img/profile/languages/MySQL.png'
import next from '/static/img/profile/languages/next.png'
import php from '/static/img/profile/languages/php.png'
import python from '/static/img/profile/languages/python.png'
import react from '/static/img/profile/languages/react.png'
import typescript from '/static/img/profile/languages/typescript.png'
import webflow from '/static/img/profile/languages/webflow.png'
import wordpress from '/static/img/profile/languages/wordpress.png'

const ProfileSlider = () => {
  const settings = {
    speed: 5000,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: true,
    swipeToSlide: true,
    centerMode: true,
    focusOnSelect: false,
    pauseOnFocus: false,
    pauseOnHover: false,
    className: 'languages--slider',
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
          speed: 3000,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 3,
          speed: 3000,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          speed: 2000,
        },
      },
    ],
  }
  return (
    <Slider {...settings}>
      <div className="item">
        <img src={bootstrap} alt="Bootstrap" />
      </div>
      <div className="item">
        <img src={c} alt="C" />
      </div>
      <div className="item">
        <img src={c_sharp} alt="C Sharp" />
      </div>
      <div className="item">
        <img src={c_plusplus} alt="C++" />
      </div>
      <div className="item">
        <img src={css3} alt="CSS3" />
      </div>
      <div className="item">
        <img src={html5} alt="HTML5" />
      </div>
      <div className="item">
        <img src={java} alt="Java" />
      </div>
      <div className="item">
        <img src={jquery} alt="jQuery" />
      </div>
      <div className="item">
        <img src={js} alt="Javascript" />
      </div>
      <div className="item">
        <img src={MySQL} alt="MySQL" />
      </div>
      <div className="item">
        <img src={next} alt="next.js" />
      </div>
      <div className="item">
        <img src={php} alt="PHP" />
      </div>
      <div className="item">
        <img src={python} alt="Python" />
      </div>
      <div className="item">
        <img src={react} alt="React" />
      </div>
      <div className="item">
        <img src={typescript} alt="Typescript" />
      </div>
      <div className="item">
        <img src={webflow} alt="Webflow" />
      </div>
      <div className="item">
        <img src={wordpress} alt="Wordpress" />
      </div>
    </Slider>
  )
}

export default ProfileSlider
